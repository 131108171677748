import React from 'react'
import Layout from "../../components/layout"
import { Link } from "gatsby"
import Footer from '../../components/footer'

import BgImage from '../../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../../components/social-icons-footer'

const network_cabling = () => {
    return (
        <Layout pageTitle="PragICTS | Network Cabling Standards">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                            <h2>Network Cabling <br/>Standards</h2>
                   
                          
                            </div>
            
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span></span></div>
                        <div className="container main-about">
                       




                            <p style={{fontSize:'14px',paddingBottom:'20px !important'}}>A properly installed network cabling system is critical for smooth and reliable functioning of an organization's IT infrastructure. </p>
                            <p style={{fontSize:'14px',paddingBottom:'20px !important'}}>High-quality cabling acts as the physical medium enabling different nodes in the network to communicate effectively and transmit data, voice, and video traffic seamlessly. It provides adequate bandwidth to support the required speeds suitable for the network architecture. </p>
                            <p style={{fontSize:'14px',paddingBottom:'20px !important'}}>Proper cabling allows flexibility to modify, expand, or rearrange the network infrastructure as needs evolve, without affecting or degrading performance of other parts of the system. It reduces bottlenecks, interference issues, and signal degradation that can occur with poor cabling. </p>
                            <p style={{fontSize:'14px',paddingBottom:'20px !important'}}>Solid network infrastructure with good cabling simplifies troubleshooting and reduces maintenance overhead. It allows easy tracing of connection routes and isolation of faults. </p>
                            <p style={{fontSize:'14px',paddingBottom:'20px !important'}}>Poor cabling often leads to slow speeds, unexpected downtimes hampering productivity, and increased costs for maintenance. Investing in good network cabling upfront provides long-term benefits by supporting efficient communications between network components.</p>
                            <p style={{fontSize:'14px',paddingBottom:'20px !important'}}>The mandatory minimum network cabling standards and guidelines of PragICTS are as follows</p>
                        

                            
                            <h2 style={{fontSize:'25px'}}>Ethernet Cable Warranty</h2>

                            <p style={{fontSize:'14px',paddingBottom:'40px !important'}}>The brand of Ethernet cables used must offer a warranty period of at least 10 years to ensure long-term reliability. 
                            <br/>25 years is ideal.</p>

                            <h2 style={{fontSize:'25px'}}>Cable Category</h2>

                            <p style={{fontSize:'14px',paddingBottom:'40px !important'}}>All Ethernet cables must be CAT-6 or higher to support high-speed data transmission and future scalability.<br/> 
                            Higher is recommended</p>

                                                <h2 style={{fontSize:'25px'}}>Maximum Cable Length</h2>

<p style={{fontSize:'14px',paddingBottom:'40px !important'}}>The maximum length of a single cable run should not exceed 90 meters. Each cable laid should be measured for accuracy.<br/>
Exceeding this limit may result in signal degradation and performance issues. </p>

                    <h2 style={{fontSize:'25px'}}>Dust-Proof Keystones</h2>

<p style={{fontSize:'14px',paddingBottom:'40px !important'}}>All Ethernet keystones should provide dust and moisture proofing to enhance durability and reduce maintenance.</p>
                    <h2 style={{fontSize:'25px'}}>Protective Cover For Cables In Open/Exposed Areas</h2>

<p style={{fontSize:'14px',paddingBottom:'40px !important'}}>Cables running through open areas must be properly enclosed with a high-quality protective covering to prevent damage from environmental factors.
</p>
                  
                    <h2 style={{fontSize:'25px'}}>Cable Installation Verification</h2>

<p style={{fontSize:'14px',paddingBottom:'40px !important'}}>Upon completion of wiring, all cable connections must pass a Fluke test with 100% success, ensuring zero packet loss across the network.<br/> 
The report should be submitted to PragICTS for verification and approval.</p>

<h2 style={{fontSize:'25px'}}>Server Rack Neatness</h2>
<p style={{fontSize:'14px',paddingBottom:'40px !important'}}>The existing server rack's neatness and organization must be maintained throughout the project.<br/>
Conforming the standard already in place.</p>

<h2 style={{fontSize:'25px'}}>Cable Labeling</h2>
<p style={{fontSize:'14px',paddingBottom:'40px !important'}}>The existing labeling standards by PragICTS should be maintained for all newly added cable points to maintain consistency in network management.</p>

                        </div>
                   

                    </section>
                 

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default network_cabling
