// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cdn-js": () => import("./../../../src/pages/cdn.js" /* webpackChunkName: "component---src-pages-cdn-js" */),
  "component---src-pages-client-only-js": () => import("./../../../src/pages/ClientOnly.js" /* webpackChunkName: "component---src-pages-client-only-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-csr-js": () => import("./../../../src/pages/csr.js" /* webpackChunkName: "component---src-pages-csr-js" */),
  "component---src-pages-ei-js": () => import("./../../../src/pages/ei.js" /* webpackChunkName: "component---src-pages-ei-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-service-delivery-models-js": () => import("./../../../src/pages/service-delivery-models.js" /* webpackChunkName: "component---src-pages-service-delivery-models-js" */),
  "component---src-pages-service-software-development-js": () => import("./../../../src/pages/service/software-development.js" /* webpackChunkName: "component---src-pages-service-software-development-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-standards-network-cabling-js": () => import("./../../../src/pages/standards/network-cabling.js" /* webpackChunkName: "component---src-pages-standards-network-cabling-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-projects-index-page-js": () => import("./../../../src/templates/ProjectsIndexPage.js" /* webpackChunkName: "component---src-templates-projects-index-page-js" */)
}

